<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import projectsTable from './components/projectsTable';

export default {
  page: {
    title: "Produits",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, projectsTable },
  data() {
    return {
      tableData: {},
      title: "Projets",
      items: [
        {
          text: "Misc",
          href: "javascript:void(0)"
        },
        {
          text: "Projets",
          active: true
        }
      ],
      
      fields: [
        { key: "name", sortable: true },
        { key: "address", sortable: true },
        { key: "analytic_account", sortable: true },
      ]
    };
  },
  
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <projects-table/>
    </div>
  </Layout>
</template>